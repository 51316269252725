import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol, toast, ToastContainer } from 'mdbreact'
import { Zoom } from 'react-toastify'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { Link } from 'gatsby'

const validationSchema = Yup.object().shape({
  country: Yup.string()
    .min(2, 'Country must be 2 characters minimum')
    .required('Country is required'),
  decline: Yup.bool().oneOf( [true,false], 'Do you decline to participate in this voluntary survey?' ),
  subscribe: Yup.bool().oneOf( [true,false], 'Terms and conditions need to be accepted' ),
})

const formUrl = 'https://script.google.com/macros/s/AKfycbzYCLIKTHjT0CgiZnBG_2o32koyv_qD-zKLtD57T3qBajwfdn8JDeHVv_sgj2KFWM7QWA/exec'

const messageConfirm = () => {
  return toast.info("Thank you.  Your survey has been submitted.", { closeButton: false, position: toast.POSITION.TOP_CENTER, transition: Zoom })
}

class formEEO extends Component {
  constructor(props, ...args) {
    super(props, ...args)
    this.state = {
      callback: 'not fired',
      value: null,
      pageUrl: null, 
      load: false,
      expired: 'false',
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ 
        load: true, 
        pageUrl: location.href 
      })
    })
  }

  handleChange = (value) => {
    this.setState({ value })
  }

  asyncScriptOnLoad = () => {
    this.setState({ callback: 'called!' })
  }

  render() {
    const { value, callback, load, expired } = this.state || {}

    return (
      <MDBContainer>
        <MDBRow className="justify-content-center">
          <MDBCol lg="9" md="12" className="md-0 mb-5">
            <Formik
              initialValues={{
                name: '',
                gender: '',
                ethnicity: '',
                country: '',
                physicaldisability: '',
                targeteddisability: '',
                usveteran: '',
                vietnamveteran: '',
                disabledveteran: '',
                otherveteran: '',
                participate: false,
                subscribe: false,
              }}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                /* alert('Form is validated! Submitting the form...') */
                let formData = {
                  Name: values.name,
                  Gender: values.gender,
                  Ethnicity: values.ethnicity,
                  Country: values.country,
                  PhysicalDisability: values.physicaldisability,
                  TargetedDisability: values.targeteddisability,
                  UsVeteran: values.usveteran,
                  VietnamVeteran: values.vietnamveteran,
                  DisabledVeteran: values.disabledveteran,
                  OtherVeteran: values.otherveteran,
                  Participate: values.participate,
                  Subscribe: values.subscribe,
                }
                try {
                  const response = axios({
                    method: 'get',
                    url: `${formUrl}?timestamp=&url=${encodeURIComponent(this.state.pageUrl)}&name=${encodeURIComponent(values.name)}&gender=${encodeURIComponent(values.gender)}&ethnicity=${encodeURIComponent(values.ethnicity)}&country=${encodeURIComponent(values.country)}&disabilityphysical=${encodeURIComponent(values.physicaldisability)}&disabilitytargeted=${encodeURIComponent(values.targeteddisability)}&veteranus=${encodeURIComponent(values.usveteran)}&veteranvietnam=${encodeURIComponent(values.vietnamveteran)}&veterandisabled=${encodeURIComponent(values.disabledveteran)}&veteranother=${encodeURIComponent(values.otherveteran)}&declineparticipation=${encodeURIComponent(values.participate)}&subscribe=${encodeURIComponent(values.subscribe)}`,
                  })
                  //console.log(response)
                  this.setState({ values: '', expired: 'true' })
                  resetForm()
                  messageConfirm() 
                } catch (e) {
                  console.log(`Axios request failed: ${e}`)
                }
                setSubmitting(false)
              }}
            >
              {({ touched, errors, values, isSubmitting }) => (
                <Form
                  name="contact"
                  method="post"
                  action=""
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                >
                  <input type="hidden" name="bot-field" />
                  <input type="hidden" name="form-name" value="eeo-survey" />

                  <section className="form-section rounded">
                    <MDBRow>
                      <MDBCol md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left mb-3 mt-3">General information</h2>
                          <hr />
                        </div>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol lg="6" md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                         <label htmlFor="name">Name</label>
                          <Field
                            type="text"
                            id="name"
                            name="name"
                            aria-label="name"
                            value={values.name}
                            placeholder="Your full name"
                            className={`form-control ${ touched.name && errors.name ? 'is-invalid' : '' }`}
                          />
                          <ErrorMessage component="div" name="name" className="invalid-feedback" />
                        </div>
                      </MDBCol>

                      <MDBCol lg="6" md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <label htmlFor="gender">Gender</label>
                          <Field
                            type="gender"
                            name="gender"
                            id="gender"
                            as="select"
                            value={values.gender}
                            placeholder="Please select an option"
                            className={`form-control ${ touched.gender && errors.gender ? 'is-invalid' : '' }`} 
                          >
                            <option value="" label="Please select a value"></option>
                            <option value="Male" label="Male"> Male </option>
                            <option value="Female" label="Female"> Female </option>
                          </Field>
                          <ErrorMessage component="div" name="gender" className="invalid-feedback" />
                        </div>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <label htmlFor="ethnicity">Ethnicity</label>
                          <Field
                            type="ethnicity"
                            name="ethnicity"
                            id="ethnicity"
                            as="select"
                            value={values.ethnicity}
                            placeholder="Please select an option"
                            className={`form-control ${ touched.ethnicity && errors.ethnicity ? 'is-invalid' : '' }`}
                          >
                            <option value="" label="Please select a value"> {' '} </option>
                            <option value="American Indian / Alaskan native" label="American Indian / Alaskan native" > American Indian / Alaskan native </option>
                            <option value="Asian" label="Asian"> Asian </option>
                            <option value="Black or African American (not of Hispanic origin)" label="Black or African American (not of Hispanic origin)" > Black or African American (not of Hispanic origin) </option>
                            <option value="Hispanic or Latino" label="Hispanic or Latino" > Hispanic or Latino </option>
                            <option value="Pacific Islander / Hawaiian native" label="Pacific Islander / Hawaiian native" > Pacific Islander / Hawaiian native </option>
                            <option value="Two or more races (persons not of Hispanic origin who identify with more than one of the above races)" label="Two or more races (persons not of Hispanic origin who identify with more than one of the above races)" > Two or more races (persons not of Hispanic origin who identify with more than one of the above races) </option>
                            <option value="White (not Hispanic or Latino)" label="White (not Hispanic or Latino)" > White (not Hispanic or Latino) </option>
                          </Field>
                          <ErrorMessage component="div" name="ethnicity" className="invalid-feedback" />
                        </div>
                      </MDBCol>

                      <MDBCol md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <label htmlFor="country">Country <span style={{ color: 'red' }}>*</span></label>
                          <Field
                            type="text"
                            id="country"
                            name="country"
                            aria-label="country"
                            value={values.country}
                            placeholder="Your country of residence"
                            className={`form-control ${ touched.country && errors.country ? 'is-invalid' : '' }`}
                          />
                          <ErrorMessage component="div" name="country" className="invalid-feedback" />
                        </div>
                      </MDBCol>
                    </MDBRow>
                  </section>

                  <section className="form-section rounded">
                    <MDBRow>
                        <MDBCol md="12">
                          <div className="mb-4 grey-text font-w-600 text-medium">
                            <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left mb-3 mt-3">Disability</h2>
                            <hr />
                          </div>
                        </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <label htmlFor="physicaldisability">Do you have any physical disabilities?</label>
                          <Field
                            type="physicaldisability"
                            name="physicaldisability"
                            id="physicaldisability"
                            as="select"
                            value={values.physicaldisability}
                            placeholder="Please select an option"
                            className={`form-control ${ touched.physicaldisability && errors.physicaldisability ? 'is-invalid' : '' }`}
                          >
                            <option value="" label="Please select a value"></option>
                            <option value="Yes" label="Yes">Yes</option>
                            <option value="No" label="No">No</option>
                          </Field>
                          <ErrorMessage component="div" name="physicaldisability" className="invalid-feedback" />
                        </div>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <label htmlFor="targeteddisability">Do you have a targeted* disability?</label>
                          <Field
                            type="targeteddisability"
                            name="targeteddisability"
                            id="targeteddisability"
                            as="select"
                            value={values.targeteddisability}
                            placeholder="Please select an option"
                            className={`form-control ${ touched.targeteddisability && errors.targeteddisability ? 'is-invalid' : '' }`}
                          >
                            <option value="" label="Please select a value"></option>
                            <option value="Yes" label="Yes">Yes</option>
                            <option value="No" label="No">No</option>
                          </Field>
                          <ErrorMessage component="div" name="targeteddisability" className="invalid-feedback" />
                        </div>
                        <div className="mb-2 grey-text font-w-400 text-medium-small">
                          * The Equal Employment Opportunity Commission targets
                          the following disabilities for extra recruitment
                          efforts: deaf, blind, missing extremities,
                          partial/complete paralysis, convulsive disorders,
                          mentally retarded, mental illness or distortion
                          limb/spine.
                        </div>
                      </MDBCol>
                    </MDBRow>
                  </section>

                  <section className="form-section rounded">
                    <MDBRow>
                      <MDBCol md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left mb-3 mt-3">US veteran status</h2>
                          <hr />
                        </div>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <label htmlFor="usveteran">Are you a United States veteran?</label>
                          <Field
                            type="usveteran"
                            name="usveteran"
                            id="usveteran"
                            as="select"
                            value={values.usveteran}
                            placeholder="Please select an option"
                            className={`form-control ${ touched.usveteran && errors.usveteran ? 'is-invalid' : '' }`}
                          >
                            <option value="" label="Please select a value"></option>
                            <option value="Yes" label="Yes">Yes</option>
                            <option value="No" label="No">No</option>
                          </Field>
                          <ErrorMessage component="div" name="usveteran" className="invalid-feedback" />
                        </div>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <label htmlFor="vietnamveteran">Vietnam era veteran</label>
                          <Field
                            type="vietnamveteran"
                            name="vietnamveteran"
                            id="vietnamveteran"
                            as="select"
                            value={values.vietnamveteran}
                            placeholder="Please select an option"
                            className={`form-control ${ touched.vietnamveteran && errors.vietnamveteran ? 'is-invalid' : '' }`}
                          >
                            <option value="" label="Please select a value"> {' '} </option>
                            <option value="Yes" label="Yes">Yes</option>
                            <option value="No" label="No">No</option>
                          </Field>
                          <ErrorMessage component="div" name="vietnamveteran" className="invalid-feedback" />
                        </div>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <label htmlFor="disabledveteran">Disabled veteran</label>
                          <Field
                            type="disabledveteran"
                            name="disabledveteran"
                            id="disabledveteran"
                            as="select"
                            value={values.disabledveteran}
                            placeholder="Please select an option"
                            className={`form-control ${ touched.disabledveteran && errors.disabledveteran ? 'is-invalid' : '' }`}
                          >
                            <option value="" label="Please select a value"></option>
                            <option value="Yes" label="Yes">Yes</option>
                            <option value="No" label="No">No</option>
                          </Field>
                          <ErrorMessage component="div" name="disabledveteran" className="invalid-feedback" />
                        </div>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <label htmlFor="otherveteran">Other</label>
                          <Field
                            type="otherveteran"
                            name="otherveteran"
                            id="otherveteran"
                            as="select"
                            value={values.otherveteran}
                            placeholder="Please select an option"
                            className={`form-control ${ touched.otherveteran && errors.otherveteran ? 'is-invalid' : '' }`}
                          >
                            <option value="" label="Please select a value"></option>
                            <option value="Yes" label="Yes">Yes</option>
                            <option value="No" label="No">No</option>
                          </Field>
                          <ErrorMessage component="div" name="otherveteran" className="invalid-feedback" />
                        </div>
                      </MDBCol>
                    </MDBRow>

                    <div className="pt-4 grey-text font-w-400 text-medium-small">
                      <strong>Vietnam era veteran:</strong> A person who served
                      on active duty for a period of more than 180 days any part
                      of which occurred between 8/5/64 and 5/7/75, and was
                      discharged or released there from with other than
                      dishonorable discharge or for a service connected
                      disability.
                      <br />
                      <br />
                      <strong>Disabled veteran</strong>: A person entitled to
                      disability compensation under laws administered by the
                      Veterans Administration for disability rated at 30% or
                      more, or a person whose discharge or release from active
                      duty was a disability incurred or aggravated in the line
                      of duty.
                    </div>
                  </section>

                  <section className="form-section rounded">
                    <MDBRow>
                      <MDBCol md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left mb-3 mt-3">This is a voluntary survey</h2>
                          <hr />
                        </div>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <div className="form-group form-check mt-3 mb-4 grey-text font-w-400 text-small">
                            <Field
                              type="checkbox"
                              id="participate"
                              name="participate"
                              aria-label="participate"
                              className={`form-check-input ${ touched.participate && errors.participate ? 'is-invalid' : '' }`}
                            />
                            <label htmlFor="participate">I decline to participate in this voluntary survey.</label>
                         </div>
                       </div>
                      </MDBCol>
                    </MDBRow>
                  </section>

                  <hr /> 

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mt-3 mb-4 grey-text font-w-400 text-small">
                        To receive occasional updates about UNICOM Global's
                        products, services, news and events, please tick the box
                        below. You can unsubscribe at any time.
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="form-group form-check mt-3 mb-4 grey-text font-w-400 text-small">
                        <Field
                          type="checkbox"
                          id="subscribe"
                          name="subscribe"
                          aria-label="subscribe"
                          className={`form-check-input ${ touched.subscribe && errors.subscribe ? 'is-invalid' : '' }`}
                        />
                        <label htmlFor="subscribe">Yes, I would like to receive communications from UNICOM Global.</label>
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 grey-text font-w-400 text-small">
                        For details on how your data is stored and used, please visit our{' '} <Link to="/privacy-statement/" className="effect"> Privacy Statement </Link> .
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12" className="mb-4">
                      <div style={{ paddingTop: '1rem' }}>
                        <button
                          type="submit"
                          className="mt-4 btn btn-medium btn-unicom"
                          style={{ display: 'block' }}
                          disabled={isSubmitting ? true : false}
                        >
                          {isSubmitting ? 'Please wait...' : 'Submit'}
                        </button>
                        <ToastContainer
                          transition={Zoom}
                          hideProgressBar={true}
                          newestOnTop={true}
                          autoClose={5000}
                        />                        </div>
                    </MDBCol>
                  </MDBRow>
                </Form>
              )}
            </Formik>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    )
  }
}

export default formEEO