import React from 'react'
import { graphql } from 'gatsby'
import { MDBContainer, MDBRow, MDBCol, MDBAnimation } from 'mdbreact'
import Layout from '../../../components/layout'
import SEO from '../../../components/seo'
import Hero from '../../../components/heroSubpage'
import FormEEO from '../../../components/formEEO'

const SubmitEEO = ({ data, location }) => {
  const post = data.markdownRemark
  return (
    <>
      <Layout>
        <div id="sub-page">
          <SEO
            title="EEO survey"
            description="If you are looking for an innovative environment where employees can make a difference, are rewarded and recognized for their hard work, then join us today."
            url={data.site.siteMetadata.siteUrl + location.pathname} 
            image={data.site.siteMetadata.siteUrl + post.frontmatter.image.childImageSharp.gatsbyImageData.images.fallback.src}            
          />
          <Hero
            class="intro-65"
            image={post.frontmatter.image.childImageSharp.gatsbyImageData}
            foreimage={null}
            alt={post.frontmatter.alttext}
            title="EEO survey"
            subtitle="Careers at UNICOM Global."
            type="root"
          />
        </div>

        <main>
        <section className="bg-white pb-0">
          <MDBContainer>
            <MDBRow>
              <MDBCol>
              <div
                    className="font-w-400 text-xs-extra-large divlink text-opening-para"
                    dangerouslySetInnerHTML={{
                      __html: post.frontmatter.openingpara,
                    }}
                  />
              <div
                className="mt-3 richtext divlink"
                dangerouslySetInnerHTML={{ __html: post.html }}
              />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>

          <section className="bg-white mt-n3">
            <MDBAnimation type="fadeIn" duration="1587ms" count={1}>
              <FormEEO />
            </MDBAnimation>
          </section>
        </main>
      </Layout>
    </>
  )
}
export default SubmitEEO

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }     
    markdownRemark(frontmatter: { name: { eq: "EEO survey" } }) {
      frontmatter {
        openingpara
        image {
          childImageSharp {
            gatsbyImageData(quality: 90) 
          }
        }
        alttext
      }
      html
    }
  }
`